<i18n locale="th" lang="yaml" >
service.field.customer_name.placeholder : "ชื่อลูกค้า"
service.field.customer_phone.placeholder : "เบอร์ติดต่อ"
service.field.customer_province.placeholder : "ระบุจังหวัด"
</i18n>

<template>
	<div class="service-customer-input">
		<a-auto-complete
			:value="sCustomerName"
			option-label-prop="value"
			style="width:250px;"
			:placeholder="$t('service.field.customer_name.placeholder')"
			@change="onChangeName"
			@select="handleSelectName"
			@search="handleSearchName">
			<template slot="dataSource">
				<a-select-option v-if="sOwner.id" :key="'owner_' + sOwner.id"  :customer-data="sOwner" class="customer-owner">
					<div class="customer-title">{{sOwner.customer_name}}</div>
					<div class="customer-detail">
						<PhoneLink :view-only="true" :value="sOwner.customer_phone" />
						<span class="province">{{$t('common.province')}}{{sOwner.customer_province}}</span>
					</div>
				</a-select-option>
      	<a-select-option v-for="customer in customers" :key="customer.id" :customer-data="customer" >
	        <div class="customer-title">{{customer.customer_name}}</div>
					<div class="customer-detail">
						<PhoneLink :view-only="true" :value="customer.customer_phone" />
						<span class="province">{{$t('common.province')}}{{customer.customer_province}}</span>
					</div>
  	    </a-select-option>
	    </template>
		</a-auto-complete>

		<a-input
			:value="sCustomerPhone"
			type="tel"
			:max-length="20"
			style="width:175px;"
			:placeholder="$t('service.field.customer_phone.placeholder')"
			@change="onChangePhone">
			<a-icon slot="prefix" type="phone" />
		</a-input>

		<a-select
			:value="sCustomerProvince"
			show-search
			style="width:200px;"
			:placeholder="$t('service.field.customer_province.placeholder')"
			on-filter-prop="children"
			:filter-option="filterOption"
			:get-popup-container="getPopupContainer"
			allow-clear
			@change="onChangeProvince">
			<a-select-option
					v-for="provinceData in provinces"
					:key="provinceData.id"
					:value="provinceData.name">
					{{provinceData.name}}
				</a-select-option>
		</a-select>
	</div>
</template>

<script>
import _debounce from "lodash/debounce"
import {AutoComplete,Select} from "ant-design-vue"
import PhoneLink from "@components/common/PhoneLink.vue"
import {mapState} from "vuex"
import axios from "axios"
import _get from "lodash/get"
import PopupMixin from "@mixins/PopupMixin.vue"
import startsWith from "lodash/startsWith"
export default {
	components : {
		PhoneLink,
		"a-auto-complete" : AutoComplete,
		"a-select" : Select,
		"a-select-option" : Select.Option,
	} ,
	mixins : [PopupMixin] ,
	props : {
		value : {
			type : null,
			default : undefined
		} ,
		droneId : {
			type : null,
			default : null
		} ,
		owner : {
			type : null,
			default : null,
		}
	} ,
	data() {
		return {
			customers : [] ,
		}
	} ,

	computed : {
		...mapState('data',['provinces']) ,
		sCustomerName() {
			return _get(this.value,'customer_name')
		} ,
		sCustomerPhone() {
			return _get(this.value,'customer_phone')
		} ,
		sCustomerProvince() {
			return _get(this.value,'customer_province')
		} ,
		sOwner() {
			if (this.owner && this.owner.id) {
				return {
					id : this.owner.id,
					customer_name : this.owner.name,
					customer_phone : this.owner.phone,
					customer_province : this.owner.province,
				}
			} else {
				return {

				}
			}
		}
	} ,
	watch : {
		droneId(){
			this.handleSearchName('')
		}
	} ,
	mounted() {
		this.handleSearchName('')
	} ,
	created() {
		this.handleSearchName = _debounce(this._handleSearchName,300)
	} ,
	methods : {
		onChangeName(name) {
			this.$emit('change',{
				...this.value,
				customer_name : name ,
			})
		} ,
		onChangePhone(event) {
			const phone = event.target.value
			this.$emit('change',{
				...this.value,
				customer_phone : phone ,
			})
		} ,
		onChangeProvince(province) {
			this.$emit('change',{
				...this.value,
				customer_province : province ,
			})
		} ,
		filterOption(input, option) {
			return (
				option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
			);
		},
		handleSelectName(value,option) {
			if (option && option.key) {
				if (startsWith(option.key,'owner_') && this.sOwner.id) {
					this.$emit('change',{
							...this.value ,
							customer_name : this.sOwner.customer_name,
							customer_phone : this.sOwner.customer_phone ,
							customer_province : this.sOwner.customer_province ,
						})
				} else {
					const data = this.customers.find((c) => c.id == option.key)
					if (data) {
						this.$emit('change',{
							...this.value ,
							customer_name : data.customer_name,
							customer_phone : data.customer_phone ,
							customer_province : data.customer_province ,
						})
					}
				}
			}
		} ,
		_handleSearchName(value) {
			const params = {
				search : {
					page_size : 8 ,
					current_page : 1,
				} ,
				filter : {
					keyword : value,
					drone_id : this.droneId
				}
			}
			axios.get("/api/services/search-customers",{params}).then((response)=>{
				this.customers = response.data.data.service_customers
			})
		}
	}
}
</script>
<style lang="less" scoped>

.customer-title {
	font-size : 0.95em;
	color : @primary-color;
}
.customer-detail {
	font-size : 0.85em;
}
</style>
<style lang="less">
.customer-owner {
	background : @blue-3;
}
</style>
